.promotions {
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.promotions-2 {
  width: 100%;
  max-width: 500px;
  height: 500px;
  margin-left: 75px;
}

.promotions-2 .carousel-item {
  display: flex;
  justify-content: center;
  align-items: center;
}

.promotions-2 .d-block {
  position: static;
}

.promotions-2 .carousel-indicators {
  display: none;
}

.promotions-2 .carousel-control-next, .promotions-2 .carousel-control-prev {
  top: 100px;
  width: 30px;
  height: 300px;
}

.promotions-2 .carousel-control-next:focus, .promotions-2 .carousel-control-next:hover, .promotions-2 .carousel-control-prev:focus, .promotions-2 .carousel-control-prev:hover {
  opacity: .7;
}

.carousel-inner {
  height: 100%;
}

.carousel-item {
  width: 100%;
  height: 100%;
}

.d-block {
  position: absolute;
  width: 500px;
  height: 300px;
  top: 115px;
  left: 60px;
}

.carousel-caption {
  top: 0;
  left: initial;
  right: 60px;
  width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: black;
}

.carousel-control-next, .carousel-control-prev {
  background-color: #696a6a;
  width: 50px;
  bottom: 0;
}

.carousel-indicators li {
  height: 4px;
  background-color: black;
}

.promotions > .carousel-control-next, .promotions > .carousel-control-prev {
  transform: scale(1.5);
  background-color: initial;
}

.promotions > .carousel-control-prev .carousel-control-prev-icon {
  width: 50px;
  height: 50px;
  background: url("./img/arrow--gray.svg") no-repeat;
  background-size: contain;
  transform: rotate(180deg);
}

.promotions > .carousel-control-next .carousel-control-next-icon {
  width: 50px;
  height: 50px;
  background: url("./img/arrow--gray.svg") no-repeat;
  background-size: contain;
}

@media (max-width: 1100px) {
  .promotions {
    height: initial;
  }

  .d-block {
    width: 340px;
    height: 470px;
    position: static;
  }

  .carousel-caption {
    position: static;
    width: 100%;
  }

  .carousel-control-next, .carousel-control-prev {
    display: none;
  }

  .carousel-indicators {
    top: 285px;
  }

  .carousel-indicators li {
    background-color: #FFF;
  }

  .promotions-2 {
    margin: 0;
    height: 350px;
    max-width: 100%;
  }

  .promotions-2 .d-block {
    width: 100%;
    max-width: 600px;
  }
}

@media (max-width: 600px) {
  .d-block {
    height: 270px;
  }

  .promotions-2 .d-block {
    max-width: 400px;
  }
}