.ag-theme-alpine .ag-header-cell, .ag-theme-alpine .ag-header-group-cell {
  padding: 0 3px;
}

.ag-theme-alpine .ag-cell, .ag-theme-alpine .ag-full-width-row .ag-cell-wrapper.ag-row-group {
  padding: 0 3px;
}

.tabs-wrapper {
  display: block;
  width: calc(100% - 250px);
  height: 100%;
}

.tab-content {
  width: 100%;
  height: calc(100% - 42px);
}

.tab-pane, .admin-table_in-tab {
  width: 100%;
  height: 100%;
}

.tab-content .ag-theme-alpine .ag-checkbox-input-wrapper {
  width: 30px;
  height: 30px;
}

.tab-content .ag-theme-alpine .ag-checkbox-input-wrapper::after {
  position: absolute;
  top: 7px;
  left: 0px;
  color: #2196f3;
  font-size: 30px;
}

.tab-content .ag-theme-alpine .ag-checkbox-input-wrapper.ag-checked::after {
  content: "\f106";
  color: #2196f3;
  color: var(--ag-checkbox-checked-color, var(--ag-alpine-active-color, #2196f3));
  position: absolute;
  top: 6.5px;
  left: 0px;
  font-size: 30px;
}