.admin-header {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
}

.admin-header label {
  margin: 0;
}

.admin-header input[type="date"] {
  margin: 0 10px;
}

.admin-header .landing-link {
  width: 250px;
  text-align: center;
  color: #007bff;
}