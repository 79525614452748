.admin-login-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 50px;
}

.authorization-error {
  display: inline-block;
  margin-left: 10px;
  color: red;
}