* {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  overflow-x: hidden;
}

header {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 50px;
}

header ul {
  height: 100%;
  padding-left: 150px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  list-style-type: none;
}

header ul li {
  margin-right: 50px;
  color: #00796b;
  font-size: 22px;
  cursor: pointer;
  position: relative;
}

header ul li:hover::after {
  position: absolute;
  content: '';
  left: -10px;
  top: 0px;
  width: 3px;
  height: 100%;
  background-color: #00796b;
}

header ul li a {
  color: inherit;
}

header ul li a:hover {
  text-decoration: none;
  color: inherit;
}

.welcome-page {
  position: relative;
  height: calc(100vh);
  background: url('./img/main.jpg') no-repeat no-repeat;
  background-size: cover;
}

h1 {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  top: 175px;
  left: 0px;
  color: #FFF;
  text-transform: uppercase;
  text-align: center;
  font-size: 37px;
  font-weight: 500;
}

.welcome-page-booking {
  position: absolute;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 150px;
}

.welcome-page-booking a {
  height: 100%;
  width: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  font-size: 25px;
  border-radius: 20px;
  background-color: #009688;
  color: #FFF;
}

.booking-cards-wrapper {
  width: 100%;
  margin-top: 50px;
  display: flex;
  justify-content: center;
}

.booking-cards {
  max-width: 1330px;
  width: 100%;
  padding: 0 15px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

.booking-card {
  width: 350px;
  height: 550px;
  margin: 0 10px 50px 10px;
  background-color: #f7f7f7;
  transition: all .2s ease-in;
}

.booking-card:hover {
  border-color: rgba(0, 150, 136, 0.4);
  transform: translateY(-10px);
  -webkit-box-shadow: 10px 10px 10px 2px rgba(0, 150, 136, 0.4);
  -moz-box-shadow: 10px 10px 10px 2px rgba(0, 150, 136, 0.4);
  box-shadow: 10px 10px 10px 2px rgba(0, 150, 136, 0.4);
}

.booking-card__prices {
  position: absolute;
  bottom: 80px;
  left: 0;
  width: 100%;
  height: 50px;
  padding: 0 1.25rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 22px;
  /* background-color: rgb(248, 184, 184); */
}

.card-image {
  width: 100%;
  height: 250px;
  background-size: cover;
}

.card {
  border-width: 3px;
  cursor: pointer;
}

.card-body {
  position: relative;
  background-color: #FFF;
}

.card-body .book-room, .booking-form .book-room {
  position: absolute;
  left: 19px;
  bottom: 20px;
  width: 304px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1d8a80;
  color: #FFF;
  font-size: 17px;
  transition: all .2s ease-in-out;
  cursor: pointer;
}

.booking-form .book-room {
  position: static;
}

.card-body .book-room:hover, .booking-form .book-room:hover {
  text-decoration: none;
  background-color: #FFF;
  color: #1d8a80;
  border: 2px solid #1d8a80;
}

.card-text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

.booking-form {
  width: 100%;
  display: flex;
  justify-content: center;
}

.booking-form-title {
  text-align: center;
}

.modal-book-room p {
  margin: 30px 0;
}

.booking-form-image {
  display: flex;
  justify-content: center;
}

.booking-form-image img {
  width: 70%;
  max-width: 1400px;
}

.booking-form input {
  border: solid 2px #1d8a80;
}

.modal-book-room .modal-dialog {
  width: 90%;
  max-width: 90%;
}

.modal-description-news {
  white-space: break-spaces;
}

.booking-form input[type="date"] {
  width: 175px;
  margin-right: 40px;
}

.booking-form .dates {
  display: flex;
  align-items: center;
}

.dates label {
  margin: 0 10px 0 0;
}

.book-room-wrapper {
  display: flex;
  justify-content: center;
}

.book-room-wrapper .booking-error {
  margin: 0;
  color: red;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 15px;
}

.youtube_video {
  display: flex;
  justify-content: center;
}

.about-us {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.about-us h3, .yandex-map-heading {
  text-align: center;
  margin: 30px 0;
  color: #1d8a80;
  font-size: 45px;
}

.about-us .text {
  width: 95%;
  font-size: 22px;
  text-align: justify;
  padding: 50px;
  border: 5px dashed #1d8a80;
}

.about-us ul {
  padding-left: 30px;
  margin: 0;
}

.yandex-map {
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}

#map {
  width: 90vw;
  height: 80vh; 
  padding: 0; 
  margin: 0;
}

footer {
  width: 100%;
  background-color: #009688;
  color: #FFF
}

footer p {
  padding: 10px 20px;
  margin: 0;
}

footer hr {
  margin: 0;
  border-color: #FFF;
}

footer a {
  color: #FFF;
}

footer a:hover {
  text-decoration: none;
  color: #FFF;
}

footer .underscore {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 600px) {
  header ul {
    padding: 0;
    justify-content: space-evenly;
  }

  header ul li {
    margin: 0;
  }

  .welcome-page-booking {
    width: 100%;
    height: 120px;
  }

  .welcome-page-booking a {
    font-size: 30px;
    text-align: center;
    padding: 5px;
    border-radius: 15px;
    border: 3px solid #FFF;
    background: initial;
    margin: 0 5px;
  }

  .booking-cards {
    padding: 0;
  }
  
  .booking-form {
    display: block;
  }

  .booking-form-image img {
    width: 100%;
  }

  .booking-form .dates {
    flex-direction: column;
    align-items: flex-start;
  }

  .booking-form .dates input {
    margin: 0;
    width: 100%;
  }

  .about-us .text {
    font-size: 20px;
    padding: 20px;
  }

  .modal-book-room .modal-dialog {
    margin: 0 auto;
    width: 97%;
    max-width: 97%;
  }

  #map {
    height: 60vh;
  }

  footer .underscore {
    flex-direction: column;
  }
}
