.edit-room {
  width: calc(100% - 290px);
}

.edit-form {
  padding-bottom: 20px;
  width: 90%;
}

.preview-image {
  margin: 15px auto; 
  width: 400px;
  height: 400px;
  display: block;
}
