.admin-table {
  width: calc(100% - 250px);
  height: 100%;
}

.admin-rooms-table {
  width: 805px;
}

.rooms-table-wrapper {
  width: calc(100% - 250px);
}

#admin-rooms-table .ag-header-cell-label {
  display: flex;
  justify-content: center;
}

.ag-react-container {
  height: 100%;
  display: flex;
  align-items: center;
}

.edit-button, .delete-button {
  padding: 0;
  width: 85px;
  height: 35px;
}

.delete-button {
  width: 75px;
}

.create-room-button, .create-booking-button {
  padding: 0;
  width: 235px;
  height: 45px;
}

.create-booking-button {
  width: 250px;
}

.save-booking-button {
  margin-left: 15px;
}

.edit-link, .create-room-link {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.create-room-link, .create-booking-link {
  color: #17a2b8;
}

.edit-link:hover, .create-room-button:hover .create-room-link, .create-booking-button:hover .create-booking-link {
  color: #FFFFFF;
  text-decoration: none;
}
