.news .carousel-caption {
  position: static;
  width: 100%;
  display: flex;
  align-items: center;
}

.news .promotions {
  height: initial;
}

.news .d-block {
  width: 340px;
  height: 470px;
  position: static;
}

.news .carousel-indicators {
  display: none;
}

.news .carousel-control-next {
  right: 30px;
}

.news .carousel-control-prev {
  left: 30px;
}

.news-2 {
  margin: 0;
  height: 350px;
  max-width: 100%;
}

.news-2 .d-block {
  width: 100%;
  max-width: 600px;
}

.news-2 .carousel-control-next,
.news-2 .carousel-control-prev {
  display: none;
}

.description-news {
  white-space: break-spaces;
}

.button-read-news {
  width: 304px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1d8a80;
  color: #FFF;
  font-size: 17px;
  transition: all .2s ease-in-out;
  cursor: pointer;
}

.button-read-news:hover {
  text-decoration: none;
  background-color: #FFF;
  color: #1d8a80;
  border: 2 px solid #1d8a80;
}

@media (max-width: 1100px) {
  .news > .carousel-control-next,
  .news > .carousel-control-prev {
    display: block;
    transform: inherit;
    padding-top: 150px;
  }
}

@media (max-width: 600px) {
  .news .d-block {
    height: 270px;
  }

  .news-2 .d-block {
    max-width: 400px;
  }
  
  .news .carousel-control-next {
    right: 5px;
  }

  .news .carousel-control-prev {
    left: 5px;
  }
}
