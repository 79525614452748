.admin-menu {
  width: 250px;
  padding: 0 10px;
}

.menu-button {
  height: 55px;
  padding: 0;
}

.menu-link {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;    
  padding: 0 0 0 20px;
  color: black;
}

.menu-link__active {
  color: #FFFFFF;
}

.menu-link:hover {
  color: inherit;
  text-decoration: none;
}